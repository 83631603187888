import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 1" />  
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 1</h1>
      <p>Hei Mamma! Litjen her! Jeg har laget adventskalender til deg, siden du alltid er så snill med meg &lt;3</p>
      <p>Hver dag, i hele advent, skal jeg underholde deg med små og store gaver.
        </p>
      <p>Jeg fikk med meg fra senga i går kveld at du trenger mer fargeblekk til noe som visstnok heter 'julekort', så jeg måtte trekke i noen tråder i natt mens du sov ;)</p>
      <p>Du er best, mamma! Kan jeg få litt melk nå, eller kanskje litt kos? Tihihihii! Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke